<template>
    <el-dialog width="500px" top="40px" title="Redigera" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <div class="py-5 px-10">
            <p>
                Från:
            </p>
            <p class="py-1 px-2 mb-4 border rounded">{{ selectedAccount.account }} {{ selectedAccount.name }}</p>
            <p>
                Till:
            </p>
            <el-select v-model="selectedNewAccount" placeholder="" class="w-full" filterable>
                <el-option v-for="item in accountsData" :key="item.number" :label="item.number + ' ' + item.name" :value="item.number" />
            </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
            <el-button @click="save" type="primary" :disabled="!selectedNewAccount">Spara</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        accountsData: {
            type: Array,
            default: () => [],
        },
        selectedAccount: {
            type: Object,
            default: () => {},
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedNewAccount: null,
        };
    },

    methods: {
        save() {
            this.$emit("updated", {
                accountToChange: this.selectedAccount.account,
                newValue: this.selectedNewAccount,
            });
            this.closeModal();
        },

        closeModal() {
            this.$emit("close");
        },

        resetModalState() {
            this.responseMessage = "";
            this.responseType = "";
            this.selectedNewAccount = null;
        },
    },
};
</script>
